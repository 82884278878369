.ym-container {
  max-width: $large;
  //   background-color: aliceblue;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: $large) {
    //do Smth
    width: 90%;
  }
}
