.projectProfile__container {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    margin-bottom: 100px;

    .image-gallery {
        max-width: $large;

        @media screen and (max-width: 961px) {


            max-width: $small;
        }
    }

    .projectProfile__heroSection {
        width: 100%;
        height: 50vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column-reverse;

    }

    .projectProfile__details {
        position: relative;
        margin-bottom: 100px;

        .profileProject_head {
            position: absolute;
            top: -100px;
            width: 100%;

            .__title {
                font-size: 2rem;
                color: rgba($color: white, $alpha: .8);
                // margin-bottom: .2rem !important;
                margin-bottom: 20px;
            }

            .__description {
                font-size: 1.23rem;
                margin: 0;
                color: rgba($color: white, $alpha: .7);
                line-height: 140%;
            }

            .projectProfile_ytVideo {
                margin: 40px 20px;
            }

            .__infos {
                font-size: 1.2rem;

                div {
                    .__infos_key {
                        font-weight: bold;
                    }

                    .__infos_value {
                        font-size: 1rem;
                        margin-left: 5px;
                    }
                }
            }

        }

    }
}