.fp-contact {
  overflow: hidden;
  height: 100vh;
  .background {
    overflow: hidden;
    // width: 100%;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.4) 100%);

    // justify-content: center;
    justify-content: space-between;
    align-items: center;
    color: white;
    .contact-footer {
      margin-top: 15rem;
    }
    .contact-container {
      padding-top: 16rem;
    }
    .body {
      width: 300px;
    }
    .mainTitle {
      font-size: 2rem;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.08em;
    }
    .contactBody {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 125%;
      color: #c8c8c8;
      letter-spacing: 0.08em;
    }
    .fillForm {
      color: white;
      cursor: pointer;
      font-weight: bold;
    }
    .desc {
      margin-top: 1.7rem;
    }
    .email {
      color: white;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .section-items {
      display: flex;
      margin-top: 20px;
      align-items: center;
      ul {
        margin: 0;
        padding: 0;
        width: -moz-max-content;
        width: max-content;
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        gap: 0;
        justify-content: flex-end;
        li {
          margin-right: 1.5rem;
          transition: all 0.3s ease-in;
          position: relative;
          text-decoration: none;
          cursor: pointer;
          color: hsla(0, 0%, 100%, 0.5);
          &::after {
            position: absolute;
            content: '';
            color: transparent;
            width: 2.25em;
            height: 1px;
            border-top: 1px solid hsla(0, 0%, 100%, 0.5);
            left: 0;
            bottom: -0.05em;
            transition: all 0.3s ease-in;
          }
          &:hover {
            transition: inherit;
            color: hsla(0, 0%, 100%, 0.8);
          }
          &#SERVICES {
            transition: inherit;
            &:hover {
              transition: inherit;
              &::after {
                width: 4.7em !important;
              }
            }
          }
          &#ABOUT_US {
            transition: inherit;
            &:hover {
              transition: inherit;
              &::after {
                width: 5.2em !important;
              }
            }
          }
          &#CONTACT {
            transition: inherit;
            &:hover {
              transition: inherit;
              &::after {
                width: 4.6em !important;
              }
            }
          }
          &#WORKS {
            transition: inherit;
            &:hover {
              transition: inherit;
              &::after {
                width: 3.8em !important;
              }
            }
          }
        }
      }
    }
  }
}
