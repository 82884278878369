.work-container {
  padding-top: 8rem;

  // height: 100vh;
  overflow: hidden !important;
  position: relative;
  display: flex;
  color: white;
  max-width: 90% !important;
  transition: all 350ms ease;

  .showFilter {
    display: none;
  }

  @media screen and (max-width: 961px) {
    display: block;

    .showFilter {
      display: block;
      padding: 10px 15px;
      margin: 10px 15px;
      background-color: transparent;
      width: fit-content;
      color: white;
      outline: none;
      border: 1px solid grey;
      border-radius: 1rem;
    }

  }

  .work-filter {
    padding-top: 8rem;
    overflow: hidden;
    position: fixed;
    margin-left: 5rem;
    top: 0;
    left: 0;
    width: 17%;
    height: 85vh;
    display: flex;
    justify-content: space-between;
    transition: all 350ms ease;
    flex-direction: column;
    background-color: black;

    .work-close {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .close {
      display: none;
      cursor: pointer;
    }

    @media screen and (max-width: 961px) {
      //do Smth
      position: fixed;
      padding-left: 2rem;
      left: 0;
      top: 0;
      margin: 0;
      width: 200px;
      z-index: 100;

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }

      .info {
        position: absolute;
        bottom: 0;
      }

      .close {
        display: block;
        font-size: 2rem;
      }
    }

    .info {
      width: 50%;
      font-size: 0.85rem;
    }

    hr {
      margin: 0.5rem auto;
      max-width: 108.5rem;
      height: 0;
      border: none;
      border-top: 1px solid hsla(0, 0%, 7%, 0.125);
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.125);
    }

    .filterTitle {
      font-size: 1.8rem;
      font-weight: 800;
      letter-spacing: 0.02em;
    }

    .filter {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.02em;
    }

    .scope {
      margin-top: 1.5rem;
      color: #999;

      .scopeTitle {
        font-size: 0.9rem;
      }

      .scopeBody {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        line-height: 0;
        cursor: pointer;
        font-size: 0.8rem;

        &:hover div {
          background-color: #ccc;
          color: white;
        }
      }
    }

    .type {
      color: #999;
      margin-top: 1.5rem;

      .typeTitle {
        font-size: 0.9rem;
      }

      .typeBody {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        line-height: 0;
        cursor: pointer;
        font-size: 0.8rem;

        &:hover div {
          background-color: #ccc;
          color: white;
        }
      }
    }
  }

  .productContainer {
    margin-left: 17%;
    max-width: 73%;
    overflow: visible;
    column-count: 3;
    column-width: 35%;
    transition: all 350ms ease;
    padding: 0 12px;

    @media screen and (max-width: 961px) {
      max-width: 90%;
      margin-left: 0;
    }

    @media screen and (max-width: 1100px) {
      column-count: 2;
    }

    @media screen and (max-width: 375px) {
      column-count: 1;
    }

    .productItem {
      transition: all 350ms ease;
      cursor: pointer;
      margin-bottom: 5px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        overflow: hidden;
        height: 100%;
      }

      .projectcard {
        position: absolute;
        bottom: -100px;
        width: 100%;
        padding: 5px 5px 0px 5px;
        height: 100px;
        background: rgb(0, 0, 6);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));

        -webkit-transition: bottom 0.5s;
        /* For Safari 3.1 to 6.0 */
        transition: bottom 0.5s;
        overflow: hidden;

        .projectcard__title {
          font-size: 1.2rem;
          overflow: hidden;
          line-height: 100%;
          margin: 0;
          height: fit-content;
          margin-bottom: 5px;
          padding: 0;

        }

        .projectcard__quickinfo {
          padding: 0;
          height: fit-content;
          overflow: hidden;
          margin: 0;
          font-size: 0.9rem;
        }
      }

      &:hover {
        .projectcard {
          bottom: -60px;
        }
      }

    }
  }
}

.real-estate {
  flex-direction: column;

  .real-estate-title {
    margin-left: 5.5% !important;

    h2 {
      font-size: 2rem;
      margin-bottom: 0 !important;
    }

    p {
      margin-top: 0 !important;
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.8);
    }

  }

  .productContainer {
    margin-left: 5%;
    max-width: 90%;
    overflow: visible;
    column-count: 4;
    column-width: 40%;

    .productItem {
      width: 100%;

    }

    img {
      width: 100%;
      overflow: hidden;
      height: auto;
    }

    @media screen and (max-width: 961px) {
      max-width: 90%;
      margin-left: 0;
    }

    @media screen and (max-width: 1100px) {
      column-count: 1;
    }

    @media screen and (max-width: 375px) {
      column-count: 1;
    }


  }
}