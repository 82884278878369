.ym-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  z-index: 4;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;

  //   padding: 0px 10px;
  div {
    padding: 0 10px;
  }

  .socialMedia {
    .social-media-icon {
      margin: 0px 3px;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
}

.general-info-footer {
  display: flex;
  align-items: center;

  .pod {
    // max-width: px;
    color: #757575;
    display: block;

    &.p1 {
      @media screen and (max-width: 740px) {
        display: none;
      }
    }

    &.p2 {
      @media screen and (max-width: 540px) {
        display: none;
      }
    }

    &.p3 {
      @media screen and (max-width: 340px) {
        display: none;
      }
    }

    .upperLine {
      width: 25px !important;
      height: 0 !important;
      border: none !important;
      margin: 0 !important;
      border-top: 1px solid #757575 !important;
      border-bottom: 1px solid #757575 !important;
      margin-bottom: 10px !important;
    }

    .title {
      font-size: 0.99rem !important;
      line-height: 2.5rem !important;
    }

    .desc {
      font-size: 0.88rem !important;
      margin: 0 !important;
    }

    .link {
      font-size: 0.88rem !important;
      color: #999;
    }
  }

  .project {
    max-width: 300px;
    color: #757575;
    text-align: right;

    .upperLine {
      width: 25px;
      height: 0;
      border: none;
      margin: 0;
      border-top: 1px solid #757575;
      border-bottom: 1px solid #757575;
      margin: 0 0 10px auto;
    }

    .title {
      font-size: 1.8rem;
      line-height: 130%;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .desc {
      margin: 0 !important;
      margin-bottom: 10px;
      font-size: 0.9rem;
    }

    .link {
      font-size: 0.88rem;
      color: #999;
      margin: 0 0 0 auto;
      width: fit-content;
    }
  }
}