.fp-about {
  overflow: hidden;
  height: 100vh;

  .background {
    overflow: hidden;
    // width: 100%;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.3) 100%);

    // justify-content: center;
    // align-items: center;
    color: white;

    .about-container {
      padding-top: 22rem;
    }

    .body {
      width: 550px;
    }

    @media screen and (max-width: 961px) {

      display: block;

      .body {
        width: inherit;
      }
    }

    .mainTitle {
      font-size: 1.8rem;
      font-weight: 900;
      line-height: 125%;
      letter-spacing: 0.08em;
      margin-bottom: 1.2rem;
    }

    .desc {
      font-size: 1.2rem;
      font-weight: 100;
      line-height: 120%;
    }

    .contentBody {
      margin-top: 1rem;
      font-size: 0.9rem;
      display: flex;
      font-weight: 500;
      line-height: 135%;
      color: #9a9a9a;
      letter-spacing: 0.08em;

      @media screen and (max-width: 961px) {
        display: block;
      }

      div {
        max-width: 275px;
        margin-bottom: 2rem;
        width: 275px !important;
      }
    }

    .fillForm {
      color: white;
      cursor: pointer;
      font-weight: bold;
    }

    .email {
      color: white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .section-items {
      display: flex;
      margin-top: 20px;
      align-items: center;

      ul {
        margin: 0;
        padding: 0;
        width: -moz-max-content;
        width: max-content;
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        gap: 0;
        justify-content: flex-end;

        li {
          margin-right: 1.5rem;
          transition: all 0.3s ease-in;
          position: relative;
          text-decoration: none;
          cursor: pointer;
          color: hsla(0, 0%, 100%, 0.5);

          &::after {
            position: absolute;
            content: '';
            color: transparent;
            width: 2.25em;
            height: 1px;
            border-top: 1px solid hsla(0, 0%, 100%, 0.5);
            left: 0;
            bottom: -0.05em;
            transition: all 0.3s ease-in;
          }

          &:hover {
            transition: inherit;
            color: hsla(0, 0%, 100%, 0.8);
          }

          &#SERVICES {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 4.7em !important;
              }
            }
          }

          &#ABOUT_US {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 5.2em !important;
              }
            }
          }

          &#CONTACT {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 4.6em !important;
              }
            }
          }

          &#WORKS {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 3.8em !important;
              }
            }
          }
        }
      }
    }
  }
}