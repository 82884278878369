.creatorInfo {
  position: fixed;
  bottom: 3vh;
  left: 10px;
  .pod {
    // max-width: px;
    color: #757575;
    display: block;
    &.p1 {
      @media screen and (max-width: 740px) {
        display: none;
      }
    }
    &.p2 {
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
    &.p3 {
      @media screen and (max-width: 340px) {
        display: none;
      }
    }
    .upperLine {
      width: 25px !important;
      height: 0 !important;
      border: none !important;
      margin: 0 !important;
      border-top: 1px solid #757575 !important;
      border-bottom: 1px solid #757575 !important;
      margin-bottom: 10px !important;
    }
    .title {
      font-size: 1rem !important;
      line-height: 2rem !important;
      font-size: bold !important;
      margin: 0;
    }
    .desc {
      font-size: 0.88rem !important;
      margin: 0 !important;
    }
    .link {
      font-size: 0.88rem !important;
      color: #999;
    }
  }
}
