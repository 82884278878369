.ymInputBox {
  width: 100%;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  background-color: #000;
  margin-top: 1rem;
  color: #999;
  line-height: 1.75;
  font-feature-settings: 'lnum' 1;
  -webkit-font-smoothing: antialiased;

  &.formik {
    width: 400px;
  }

  input {
    font-size: 1rem;
    width: 100%;
    letter-spacing: 0.025em;
    font-weight: 400;
    font-family: Nunito Sans, sans-serif;
    margin: 0;
    padding: 0.875rem;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #121212;
    border-radius: 0;
    color-scheme: dark;
    line-height: 1.2;
    color: #fff;
    background-color: #121212;
    outline: none;
  }

  textarea {
    font-size: 1rem;
    width: 100%;
    letter-spacing: 0.025em;
    font-weight: 400;
    font-family: Nunito Sans, sans-serif;
    margin: 0;
    padding: 0.875rem;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #121212;
    border-radius: 0;
    color-scheme: dark;
    line-height: 1.2;
    color: #fff;
    background-color: #121212;
    outline: none;
  }
}

.YMformikSumbit {
  margin-top: 1rem;
  width: 100%;
  background-color: #121212;
  font-size: 1.2rem;
  outline: none;
  border-radius: 1rem;
  cursor: pointer;
  color: #989898;
  border: 1px solid #1d1d1dce;
  padding: 1rem;

  &:disabled {
    cursor: default;
    background-color: #121212;

    &:hover {
      color: #989898;
      background-color: #121212;
    }
  }

  &:hover {
    background-color: #1d1d1d;
    color: #fff;
  }
}

.YMButton {
  margin-top: 1rem;
  font-size: 1.2rem;

  &:hover {
    color: #fff;
  }
}


.dragDropZone {
  display: flex;

  margin-top: 2.2rem;
  color: #999;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #494949;
    background-color: #121212;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    color: #999;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    color: #999;
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .droppedImages {
    img {
      display: inline;
      width: 100px;
      height: auto;
    }
  }
}

.ymDropdown {
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  background-color: #000;
  margin-top: 1rem;
  color: #999;
  line-height: 1.75;
  font-feature-settings: 'lnum' 1;
  -webkit-font-smoothing: antialiased;

  .react-select__multi-value {
    background-color: #2c2c2c;

    .react-select__multi-value__label {
      color: white !important;
    }
  }

  .react-select-container {
    width: 100%;
    margin-top: 0.2rem;

    .css-1dimb5e-singleValue {
      color: white !important;
    }

    .react-select__menu {
      background-color: #121212;

      .react-select__menu-list {
        background-color: #121212;

        .react-select__option {
          background-color: #121212;

          &:hover {
            background-color: #363636;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 2px solid indigo;
  border-radius: 50%;
  border-top-color: #0001;
  display: inline-block;
  animation: loadingspinner 0.7s linear infinite;
}

@keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}