@import './misc/variable.styles.scss';
@import './misc/imports.styles.scss';
@import './header/header.style.scss';
@import './home/home.style.scss';
@import './misc/ymContainer.style.scss';
@import './footer/footer.style.scss';
@import './requestProposal/requestProposal.style.scss';
@import './contact/contact.style.scss';
@import './about/about.style.scss';
@import './service/service.style.scss';
@import './work/work.style.scss';
@import './misc/radiobutton.styles.scss';
@import './contact/creator.style.scss';
@import './admin/admin.style.scss';
@import './admin/table.style.scss';
@import './admin/login.scss';
@import './common/common.style.scss';
@import './common/toast/toast.style.scss';
@import './projectProfile/projectProfile.style.scss';
@import './gridImage.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  font-size: 14px;
  letter-spacing: 0.02em;
  background-color: #000;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @media screen and (max-width: $large) {
    //do Smth
    font-size: 12px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
  line-height: 1.75;
  font-feature-settings: 'lnum' 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.goto-arrow {
  display: flex;
  white-space: nowrap;
  align-items: center;
  transition: all 0.3s ease-in;
  cursor: pointer;

  .HiArrowRight {
    margin-left: 5px;
    transition: all 0.3s ease-in;

    &.show {
      display: flex;
    }

    &.hide {
      display: none;
    }
  }

  &:hover {
    .HiArrowRight {
      &.show {
        display: flex;
      }

      &.hide {
        display: flex;
      }

      margin-left: 10px;
      transition: all 0.3s ease-in;
    }
  }
}

video {
  // mix-blend-mode: overlay;
  // -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#Hotspot {
  background: rgb(255, 255, 255);
  border-radius: 32px;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s ease 0s;
  width: 24px;
}

#HotspotAnnotation {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

#ymModelViewer {
  width: 100%;
  height: 100%;
}

.modelContainer * {
  width: 100%;
  height: 100%;
}

.real-estate-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    padding: 10px 15px;
    font-size: 1.2rem;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: white;
    margin: 15px auto;
  }
}

iframe {
  width: 100% !important;
}

#loadingPoster {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  align-items: center;
  background-color: #000000d8;
  justify-content: space-around;

  img {
    width: 50%;
    height: auto;
  }
}