.fp-home {
  overflow: hidden;
  height: 100vh;

  .background {
    overflow: hidden;
    width: 100%;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    // justify-content: center;
    // align-items: center;
    color: white;

    .home-container {
      padding-top: 150px;
    }

    .mainTitle {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.0875;
      letter-spacing: 0.08em;
    }

    .section-items {
      display: flex;
      margin-top: 20px;
      align-items: center;

      ul {
        margin: 0;
        padding: 0;
        width: -moz-max-content;
        width: max-content;
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        gap: 0;
        justify-content: flex-end;

        li {
          margin-right: 1.5rem;
          transition: all 0.3s ease-in;
          position: relative;
          text-decoration: none;
          cursor: pointer;
          color: hsla(0, 0%, 100%, 0.5);

          &::after {
            position: absolute;
            content: '';
            color: transparent;
            width: 2.25em;
            height: 1px;
            border-top: 1px solid hsla(0, 0%, 100%, 0.5);
            left: 0;
            bottom: -0.05em;
            transition: all 0.3s ease-in;
          }

          &:hover {
            transition: inherit;
            color: hsla(0, 0%, 100%, 0.8);
          }

          &#SERVICES {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 4.7em !important;
              }
            }
          }

          &#REAL_ESTATE {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 6.2em !important;
              }
            }
          }

          &#ABOUT_US {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 5.2em !important;
              }
            }
          }

          &#CONTACT {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 4.6em !important;
              }
            }
          }

          &#WORKS {
            transition: inherit;

            &:hover {
              transition: inherit;

              &::after {
                width: 3.8em !important;
              }
            }
          }
        }
      }
    }
  }
}