.header-container {
  position: fixed;
  top: 0;
  right: 0;
  width: fill;
  // height: 80px;
  z-index: 5;
  .ym-header {
    background-color: rgba(0, 0, 0, 1);
    position: absolute;
    top: 0;
    right: 0;
    width: fill;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1951374299719888) 0%,
      rgba(0, 0, 0, 0.029871323529411797) 50%,
      rgba(0, 212, 255, 0) 100%
    );
    display: flex;
    align-items: center;
    padding: 30px 50px;
    justify-content: space-between;
    z-index: 7;
    .menu {
      font-size: larger;
      color: rgb(208, 208, 208);
      cursor: pointer;
      &:hover {
        color: rgb(234, 234, 234);
      }
    }
    .ym-logo {
      width: 50px;
      cursor: pointer;
      height: auto;
    }
  }
}
.ym-header-menu {
  width: 100%;
  z-index: 6;
  height: 100vh;
  background-color: black;

  // position: absolute;
  // top: 0;
  // left: 0;
  .box {
    display: flex;
    height: 95%;
    justify-content: space-between;
    flex-direction: column;
  }

  ul {
    margin: 0;
    padding: 0;
    width: -moz-max-content;
    width: max-content;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
    justify-content: flex-end;
    font-size: 2.3rem;
    font-family: sans-serif;
    font-weight: 600;
    color: #fff;
    padding-top: 150px;
    transition: all 0.2s ease-in;
    &:hover {
      color: #999;
    }
    li {
      line-height: 150%;
      transition: all 0.2s ease-in;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}
