.admin {
  width: 100%;
  height: 100vh;
  margin-top: 14rem;
  margin-left: 14rem;
  overflow: hidden;

  .adminItem {
    font-size: 1.8rem;

    &:hover {
      color: white;
    }
  }
}

.createAProject {
  width: 400px;
  height: 90vh;
  margin: 10rem auto;
  overflow: scroll;

  h3 {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.8);
  }
}

.textRichBoxCustomYM {
  margin-top: 2rem;

  .quill {
    .ql-toolbar {
      border-color: rgba(255, 255, 255, 0.237) !important;
    }

    .ql-container {
      border-color: rgba(255, 255, 255, 0.237) !important;
      font-size: 1rem;
      width: 100%;
      letter-spacing: 0.025em;
      font-weight: 400;
      font-family: Nunito Sans, sans-serif;
      margin: 0;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #121212;
      border-radius: 0;
      color-scheme: dark;
      line-height: 1.2;
      color: #fff;
      background-color: #121212;
      outline: none;
    }
  }
}

.HotSpotField {
  margin: 10px 0;
  width: 100%;
  border-top: 1px solid #4a4a4a;

  .hs_title {
    font-size: 1.3rem;
  }
}