@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

.table {
    font-family: "Quicksand", sans-serif;

    padding: 0;
    color: rgba($color: white, $alpha: 0.8);
}

#title {
    text-align: center;
}

#employee {
    border-collapse: collapse;
    border: 3px solid rgba($color: white, $alpha: 0.2);
}

#employee td,
#employee th {
    border: 1px solid rgba($color: white, $alpha: 0.2);
    padding: 12px;
}



#employee th {
    padding: 10px;
    text-align: center;
    background-color: rgba($color: white, $alpha: 0.1);
    color: rgba($color: white, $alpha: 0.7);
}

.operation {
    text-align: center;
}

.button {
    border: none;
    outline: none;
    font-size: 11px;
    font-family: "Quicksand", sans-serif;
    color: #f44336;
    padding: 3px 10px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f44336;
    background-color: transparent;
}

.button:active {
    border: 1px solid blue;
}

.projects_table_container {
    padding-top: 10rem;
    width: 50%;
    margin: 0 auto;
}