.fp-service {
  overflow: hidden;
  height: 100vh;
  .background {
    overflow: hidden;
    @media screen and (max-width: $medium) {
      //do Smth
      display: none;
    }
    // width: 100%;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.3) 100%);

    // justify-content: center;
    // align-items: center;
    color: white;
    .service-container {
      padding-top: 22rem;
      @media screen and (max-width: $medium) {
        //do Smth
        padding-top: 10rem;
      }
    }
    .body {
      width: 550px;
      @media screen and (max-width: $medium) {
        //do Smth
        width: 95% !important;
      }
    }
    .mainTitle {
      font-size: 2rem;
      font-weight: 900;
      line-height: 125%;
      letter-spacing: 0.08em;
      margin-bottom: 1.2rem;
      @media screen and (max-width: $medium) {
        font-size: 2.8rem;
        font-weight: 900;
      }
    }
    .desc {
      font-size: 1.2rem;
      font-weight: 100;
      line-height: 120%;
      @media screen and (max-width: $medium) {
        margin-top: 2rem;
        font-size: 1.5rem;
      }
    }
    .contentBody {
      margin-top: 1rem;
      font-size: 0.9rem;
      display: flex;
      font-weight: 500;
      line-height: 135%;
      color: #9a9a9a;
      letter-spacing: 0.08em;
      @media screen and (max-width: $medium) {
        //do Smth
        display: none;
      }
      div {
        max-width: 275px;
        width: 275px !important;
      }
      .serviceItems {
        ul {
          margin: 0;
          padding: 0;
          width: -moz-max-content;
          width: max-content;
          transition: all 0.3s ease-in;
          list-style: none;
          flex-wrap: nowrap;
          gap: 0;
          justify-content: flex-end;
          li {
            font-size: 1.2rem;
            transition: all 0.3s ease-in;
            line-height: 170%;
            color: #9a9a9a;
            cursor: pointer;
            &:hover {
              transition: all 0.3s ease-in;
              color: rgb(225, 225, 225);
            }
            &.selected {
              color: white;
            }
          }
        }
      }
      .serviceDesc {
        transition: all 0.3s ease-in;
      }
    }

    .contentBody_mobile {
      display: none;
      @media screen and (max-width: $medium) {
        margin-top: 2rem;
        font-size: 1.5rem;
        display: block;
      }
      height: 65vh;

      overflow-y: scroll;
      .cm_title {
        margin-top: 3rem;
        font-size: 2rem;
      }
      .cm_body {
        margin-top: 0.6rem;
        font-size: 1.5rem;
        font-weight: light;
        color: #ababab;
      }
    }
  }
}
