.requestAProposal {
  display: flex;
  align-items: center;
  justify-content: right;

  .form {
    width: 50%;
    margin-right: 60px;
    // background-color: rgba(240, 248, 255, 0.1);
    iframe {
      width: 100%;
      height: 80vh;
      border: none;
    }
  }
  .sideImage {
    width: 50%;
    display: block;
    height: 100vh;
  }
  @media screen and (max-width: $large) {
    width: 100%;
  }
  @media screen and (max-width: 980px) {
    .form {
      width: 90%;
      margin-top: 120px;
      margin-right: 0;
      iframe {
        width: 100%;
        height: 80vh;
        border: none;
      }
    }
    .sideImage {
      display: none;
    }
  }
}
