@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Poppins:ital@1&display=swap');


.loginContainer {
    --color-primary: #2d3436;
    --color-secondary: #252c6a;
    --border-radius: 4px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
}

.container {
    width: 400px;
    max-width: 400px;
    margin: 1rem;
    padding: 2rem;
    border-radius: var(--border-radius);
    background: rgba($color: white, $alpha: 0.1);
}

.form--hidden {
    display: none;
}

.form>*:first-child {
    margin-top: 0;
}

.form>*:last-child {
    margin-bottom: 0;
}

.form__title {
    margin-bottom: 2rem;
    text-align: center;
}

.form__input-group {
    margin-bottom: 1rem;
}

.form__input {
    display: block;
    width: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: 1px solid #ddd;
    outline: none;
    background: #eee;
    transition: background 0.2s border-color 0.2s;
}

.form__input:focus {
    border-color: var(--color-primary);
    background: #fff;
}

.form__button {
    width: 100%;
    padding: 1rem 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #fff;
    border: none;
    border-radius: var(--border-radius);
    outline: none;
    cursor: pointer;
    background: var(--color-primary);
    -webkit-user-select: none;
}

.form__button:active {
    transform: scale(0.98);
}

.padding {
    padding-bottom: 10px;
}