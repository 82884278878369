@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

.gridGallery {


    /* Reset default styles */
    ul,
    li,
    button {
        margin: 0;
        padding: 0;
    }

    button {
        border: none;
        background-color: transparent;
    }

    /* Image gallery styles */





    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 10px;
    }

    .gallery__item {
        overflow: hidden;
    }

    .gallery__image {
        width: 100%;
        height: auto;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        cursor: pointer;
    }

    .gallery__item:hover {
        opacity: 0.8;
    }

    .gallery__item:hover .gallery__image {
        transform: scale(1.3);
    }

    /* Lightbox styles */
    .lightbox {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lightbox__image {
        max-width: 90%;
        max-height: 90%;
    }

    /* Close lightbox */

    .lightbox__close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
    }

    .lightbox__close-button:hover {
        opacity: 0.5;
    }

    .lightbox__bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: -1;
        cursor: pointer;
    }
}