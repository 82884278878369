.custonRadioButton {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 2px solid #999;
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  background-color: #000 !important;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 1px solid #999;
  outline: none;
  cursor: pointer;
  width: 12px;
  height: 12px;

  &:checked::before {
    content: '\2713';
    font-size: 1.5em;
    color: #fff;
    display: inline-block;

    position: absolute;
    right: 1px;
    top: -5px;
  }
}
